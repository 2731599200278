import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const AgentsList = () => {
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchAgents();
  }, []);

  const fetchAgents = async () => {
    try {
      setLoading(true);
      const response = await fetch('/api/agents');
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setAgents(data.agents);
    } catch (error) {
      setError('Failed to load agents');
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="agents-list">
      <h1>Sales Agents</h1>
      <div className="agents-grid">
        {agents.map(agent => (
          <Link to={`/agents/${agent.id}`} key={agent.id} className="agent-card">
            <div className="agent-info">
              <h2>{agent.full_name}</h2>
              <p>{agent.email}</p>
            </div>
            <div className="agent-stats">
              <div className="stat">
                <label>Total Chats</label>
                <span>{agent.performance.total_chats}</span>
              </div>
              <div className="stat">
                <label>Overall Score</label>
                <span>{agent.performance.avg_scores.overall.toFixed(1)}/10</span>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default AgentsList; 