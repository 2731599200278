import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const Header = () => {
  const location = useLocation();
  const { user, signOut, getToken } = useAuth();
  
  const isActive = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  const handleAnalyticsClick = async (e) => {
    e.preventDefault();
    
    if (!user) {
      // Redirect to login if not authenticated
      window.location.href = '/login';
      return;
    }
    
    try {
      // Get the token using the context function
      const token = await getToken();
      const email = user.email || '';
      
      console.log("Opening Streamlit with auth:", { 
        email, 
        token: token ? `${token.substring(0, 10)}...` : "empty" 
      });
      
      // Construct the URL with authentication parameters
      const streamlitUrl = `/dashboard/?token=${encodeURIComponent(token || '')}&email=${encodeURIComponent(email || '')}`;
      
      // Open in the same window
      window.location.href = streamlitUrl;
    } catch (error) {
      console.error("Error preparing Streamlit URL:", error);
      alert("Error accessing analytics dashboard. Please try again.");
    }
  };

  return (
    <header className="main-header">
      <div className="header-content">
        <div className="logo">
          <Link to="/">Sales Monitor</Link>
        </div>
        <nav className="main-nav">
          <Link to="/" className={`nav-item ${isActive('/')}`}>
            <span className="icon">📊</span>
            Dashboard
          </Link>
          {user && (
            <button 
              className="nav-item link-button" 
              onClick={handleAnalyticsClick}
            >
              Analytics Dashboard
            </button>
          )}
        </nav>
        
        <div className="user-menu">
          {user && (
            <>
              <div className="user-info">
                <span className="user-email">{user.email}</span>
              </div>
              <button className="sign-out-button" onClick={signOut}>
                Sign Out
              </button>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header; 