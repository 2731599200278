import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';
import { supabase } from '../supabaseClient';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

const ALLOWED_EMAILS = ['mert.gercek@clinichub.com'];

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [accessCheckCache, setAccessCheckCache] = useState({});

  const checkEmailAccess = useCallback(async (user) => {
    if (!user) return false;
    
    const email = user.email;
    
    // Check cache first
    if (accessCheckCache[email] !== undefined) {
      console.log("Using cached access check result for:", email);
      return accessCheckCache[email];
    }
    
    try {
      // Set a timeout for the access check
      const timeoutPromise = new Promise((_, reject) => 
        setTimeout(() => reject(new Error('Access check timed out')), 3000)
      );
      
      // Get the session to extract the token
      const { data: { session } } = await supabase.auth.getSession();
      const token = session?.access_token;
      
      console.log("Checking access with token:", token ? `${token.substring(0, 10)}...` : "No token");
      
      if (!token) {
        console.error("No access token found in session");
        return false;
      }
      
      // For mert.gercek@clinichub.com, bypass the backend check
      if (ALLOWED_EMAILS.includes(email)) {
        console.log("Email in allowed list, bypassing backend check");
        setAccessCheckCache(prev => ({...prev, [email]: true}));
        return true;
      }
      
      // Race the fetch against the timeout
      const accessCheckPromise = fetch('/api/auth/verify', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });
      
      const response = await Promise.race([accessCheckPromise, timeoutPromise]);
      console.log("Verify response status:", response.status);
      
      const data = await response.json();
      console.log("Verify response data:", data);
      
      const allowed = !!data.allowed;
      
      // Cache the result
      setAccessCheckCache(prev => ({...prev, [email]: allowed}));
      
      if (!allowed) {
        // If not allowed, sign out
        await supabase.auth.signOut();
        setError(data.message || 'Your email is not authorized to access this application');
        return false;
      }
      
      return true;
    } catch (error) {
      console.error('Error checking email access:', error);
      
      // If it's a timeout, assume access is allowed for now
      if (error.message === 'Access check timed out') {
        console.log("Access check timed out, assuming access is allowed");
        return true;
      }
      
      setError('Failed to verify access permissions');
      return false;
    }
  }, [accessCheckCache, setAccessCheckCache, setError]);

  useEffect(() => {
    // First check for direct login
    const checkAuth = async () => {
      try {
        setLoading(true);
        
        // Check for direct login first (this is fast)
        const storedAuth = localStorage.getItem('test_auth');
        if (storedAuth) {
          try {
            const authData = JSON.parse(storedAuth);
            console.log("Found direct login data:", authData);
            setUser({
              email: authData.email,
              directLogin: true
            });
            setLoading(false);
            return; // Exit early if direct login found
          } catch (error) {
            console.error("Error parsing direct login data:", error);
          }
        }
        
        // Set a timeout for the entire auth check process
        const authCheckTimeout = setTimeout(() => {
          console.log("Auth check taking too long, proceeding to login");
          setLoading(false);
        }, 5000);
        
        // Check Supabase session
        const { data: { session } } = await supabase.auth.getSession();
        
        if (session?.user) {
          console.log("Found Supabase session, checking access...");
          const email = session.user.email;
          
          // For known allowed emails, bypass the access check
          if (email === 'mert.gercek@clinichub.com') {
            console.log("Known allowed email, bypassing access check");
            setUser(session.user);
            clearTimeout(authCheckTimeout);
            setLoading(false);
            return;
          }
          
          const hasAccess = await checkEmailAccess(session.user);
          if (hasAccess) {
            setUser(session.user);
          } else {
            await supabase.auth.signOut();
            setUser(null);
          }
        } else {
          console.log("No Supabase session found");
          setUser(null);
        }
        
        clearTimeout(authCheckTimeout);
      } catch (error) {
        console.error('Error in auth check:', error);
        setError(error.message);
        setUser(null);
      } finally {
        setLoading(false);
      }
    };
    
    checkAuth();
    
    // Set up auth state change listener
    const { data: { subscription } } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        console.log("Auth state changed:", event);
        
        if (event === 'SIGNED_OUT') {
          setUser(null);
          setLoading(false);
          return;
        }
        
        if (session?.user && event === 'SIGNED_IN') {
          const email = session.user.email;
          
          // For known allowed emails, bypass the access check
          if (ALLOWED_EMAILS.includes(email)) {
            console.log("Known allowed email, bypassing access check");
            setUser(session.user);
            setLoading(false);
            return;
          }
          
          const hasAccess = await checkEmailAccess(session.user);
          if (hasAccess) {
            setUser(session.user);
          } else {
            await supabase.auth.signOut();
            setUser(null);
          }
          setLoading(false);
        }
      }
    );
    
    return () => {
      subscription.unsubscribe();
    };
  }, [checkEmailAccess]);

  const signOut = async () => {
    try {
      if (user?.directLogin) {
        localStorage.removeItem('test_auth');
        setUser(null);
      } else {
        await supabase.auth.signOut();
      }
    } catch (error) {
      console.error('Error signing out:', error);
      setError(error.message);
    }
  };

  const getToken = async () => {
    try {
      // First try to get token from Supabase
      const { data, error } = await supabase.auth.getSession();
      
      if (data && data.session && data.session.access_token) {
        console.log("Retrieved token from Supabase session");
        return data.session.access_token;
      }
      
      // If no Supabase token, check for direct login token
      const storedAuth = localStorage.getItem('test_auth');
      if (storedAuth) {
        try {
          const authData = JSON.parse(storedAuth);
          console.log("Using direct login token");
          return authData.token;
        } catch (error) {
          console.error("Error parsing direct login data:", error);
        }
      }
      
      console.warn("No authentication token available");
      return null;
    } catch (error) {
      console.error("Error getting token:", error);
      return null;
    }
  };

  const value = {
    user,
    loading,
    error,
    signOut,
    isAuthenticated: !!user,
    getToken: getToken
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}; 