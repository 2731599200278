import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';

const AgentProfile = () => {
  const [agent, setAgent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { agentId } = useParams();

  const fetchAgentProfile = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetch(`/api/agents/${agentId}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setAgent(data.agent);
    } catch (error) {
      setError('Failed to load agent profile');
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  }, [agentId]);

  useEffect(() => {
    fetchAgentProfile();
  }, [fetchAgentProfile]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="error">{error}</div>;
  if (!agent) return <div>Agent not found</div>;

  return (
    <div className="agent-profile">
      <header className="agent-header">
        <h1>{agent.full_name}</h1>
        <p>{agent.email}</p>
      </header>

      <section className="performance-overview">
        <h2>Performance Overview</h2>
        <div className="metrics-grid">
          <div className="metric">
            <label>Total Chats</label>
            <span>{agent.performance.total_chats}</span>
          </div>
          <div className="metric">
            <label>Overall Score</label>
            <span>{agent.performance.avg_scores.overall.toFixed(1)}/10</span>
          </div>
          {/* Add other metrics */}
        </div>
      </section>

      <section className="recent-chats">
        <h2>Recent Chats</h2>
        <div className="chat-list">
          {agent.recent_chats.map(chat => (
            <div key={chat.id} className="chat-item">
              <span className="ticket-id">#{chat.ticket_id}</span>
              <span className="date">
                {new Date(chat.created_at).toLocaleDateString()}
              </span>
              <span className="score">{chat.overall_score.toFixed(1)}/10</span>
              <div className="labels">
                {chat.labels.map(label => (
                  <span key={label} className="label">{label}</span>
                ))}
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default AgentProfile; 