import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from './components/Header';
import AppRoutes from './routes';
import ThemeToggle from './components/ThemeToggle';
import { AuthProvider } from './contexts/AuthContext';
import './styles/main.css';

const App = () => {
  const location = useLocation();
  const isAuthPage = location.pathname === '/login' || location.pathname === '/auth/callback';

  return (
    <AuthProvider>
      <div className="app-container">
        {!isAuthPage && <Header />}
        <main className={`main-content ${isAuthPage ? 'auth-page' : ''}`}>
          <AppRoutes />
        </main>
        {!isAuthPage && <ThemeToggle />}
      </div>
    </AuthProvider>
  );
};

export default App; 