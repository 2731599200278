import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

console.log("Supabase initialization with:", { 
  url: supabaseUrl, 
  key: supabaseAnonKey ? "Key exists" : "Key missing" 
});

if (!supabaseUrl || !supabaseAnonKey) {
  console.error('Missing Supabase environment variables. Check your .env file.');
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey);

// Test the connection
supabase.auth.onAuthStateChange((event, session) => {
  console.log("Auth state changed:", event, session ? "Session exists" : "No session");
}); 