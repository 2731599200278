import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const ProtectedRoute = () => {
  const { isAuthenticated, loading, error } = useAuth();

  // Show a nicer loading indicator with a timeout
  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
        <p>Loading your account...</p>
        {/* Add a timeout message after 5 seconds */}
        <div className="loading-timeout">
          {setTimeout(() => {
            document.querySelector('.loading-timeout')?.classList.add('visible');
          }, 5000) && (
            <p className="timeout-message">
              This is taking longer than expected. If you continue to see this message,
              please try refreshing the page or logging in again.
            </p>
          )}
        </div>
      </div>
    );
  }

  // If there's an error, show it and redirect
  if (error) {
    return (
      <div className="error-container">
        <h2>Authentication Error</h2>
        <p>{error}</p>
        <button onClick={() => window.location.href = '/login'}>
          Go to Login
        </button>
      </div>
    );
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" replace />;
};

export default ProtectedRoute; 