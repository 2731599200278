import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';

const AuthCallback = () => {
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleAuthCallback = async () => {
      try {
        console.log("Auth callback triggered, current URL:", window.location.href);
        
        // The hash contains the token
        const { data, error } = await supabase.auth.getSession();
        
        console.log("Auth session result:", { 
          hasData: !!data, 
          hasSession: !!(data && data.session),
          error: error ? error.message : null
        });
        
        if (error) {
          throw error;
        }
        
        // Redirect to the dashboard
        console.log("Authentication successful, redirecting to dashboard");
        navigate('/', { replace: true });
      } catch (error) {
        console.error('Error during auth callback:', error);
        setError(error.message);
        navigate('/login', { replace: true });
      }
    };

    handleAuthCallback();
  }, [navigate]);

  if (error) {
    return (
      <div className="auth-callback-error">
        <h2>Authentication Error</h2>
        <p>{error}</p>
        <button onClick={() => navigate('/login')}>Return to Login</button>
      </div>
    );
  }

  return (
    <div className="auth-callback-loading">
      <h2>Completing authentication...</h2>
      <div className="loading-spinner"></div>
    </div>
  );
};

export default AuthCallback; 