import React from 'react';

const getScoreClass = (score) => {
  if (!score) return '';
  if (score >= 8) return 'high';
  if (score >= 6) return 'medium';
  return 'low';
};

const ChatList = ({ chats = [], onSelectChat, loading, page, totalPages, onPageChange }) => {
  if (loading) return <div className="loading">Loading chats...</div>;
  
  if (!chats || chats.length === 0) {
    return <div className="no-chats">No chats found</div>;
  }

  return (
    <div className="chat-list">
      <h2>Recent Chats</h2>
      {chats.map(chat => (
        <div 
          key={chat.ticket_id} 
          className={`chat-item ${getScoreClass(chat.scores?.overall)}`}
          onClick={() => onSelectChat(chat)}
        >
          <div className="chat-header">
            <div className="chat-title">
              <span className="ticket-id">#{chat.ticket_id}</span>
              {chat.agents?.find(agent => agent.is_primary) && (
                <span className="agent-name">{chat.agents.find(agent => agent.is_primary).full_name}</span>
              )}
            </div>
            <span className="date">
              {new Date(chat.created_at).toLocaleDateString()}
            </span>
          </div>
          <div className="chat-meta">
            <div className="chat-labels">
              {chat.labels && chat.labels.map(label => (
                <span key={label} className="label">{label}</span>
              ))}
            </div>
            {chat.scores && (
              <div className="score-badge" title="Overall Performance">
                {chat.scores.overall}/10
              </div>
            )}
          </div>
          <div className="chat-summary">
            <span className="message-count">
              {chat.agent_message_count + chat.customer_message_count} messages
            </span>
          </div>
        </div>
      ))}
      {totalPages > 1 && (
        <div className="pagination">
          <button 
            onClick={() => onPageChange(page - 1)}
            disabled={page === 1}
          >
            Previous
          </button>
          <span>Page {page} of {totalPages}</span>
          <button 
            onClick={() => onPageChange(page + 1)}
            disabled={page === totalPages}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default ChatList; 