import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if user is already logged in
    const checkSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (session) {
        navigate('/');
      }
    };
    
    checkSession();
  }, [navigate]);

  const handleGoogleLogin = async () => {
    try {
      setLoading(true);
      setError(null);
      
      // Get the current origin for proper redirect
      const currentOrigin = window.location.origin;
      console.log("Current origin for redirect:", currentOrigin);
      
      // Log environment variables that might affect the redirect
      console.log("Environment variables:", {
        REACT_APP_SUPABASE_URL: process.env.REACT_APP_SUPABASE_URL,
        NODE_ENV: process.env.NODE_ENV,
        // Add any other relevant environment variables
      });
      
      // Force the redirect URL to be the current origin
      const redirectUrl = `${currentOrigin}/auth/callback`;
      console.log("Setting redirect URL to:", redirectUrl);
      
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: redirectUrl,
          // Log the full options object
          queryParams: {
            // Add a debug parameter to track the source
            debug_source: 'production_app'
          }
        }
      });
      
      console.log("Sign in response:", { data, error });
      
      if (error) throw error;
    } catch (error) {
      console.error("Login error details:", error);
      setError(error.message || 'An error occurred during login');
    } finally {
      setLoading(false);
    }
  };

  const handleDirectLogin = async () => {
    try {
      setLoading(true);
      setError(null);
      
      console.log("Testing direct login...");
      
      // Call our backend direct login endpoint
      const response = await fetch('/api/auth/direct-login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: 'mert.gercek@clinichub.com' }),
      });
      
      const data = await response.json();
      console.log("Direct login response:", data);
      
      if (!data.allowed) {
        throw new Error(data.message || 'Not authorized');
      }
      
      // For testing only - normally we'd use Supabase auth
      localStorage.setItem('test_auth', JSON.stringify({
        email: data.email,
        token: data.test_token
      }));
      
      navigate('/');
      
    } catch (error) {
      console.error("Direct login error:", error);
      setError(error.message || 'An error occurred during login');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <div className="login-header">
          <h1>Sales Quality Monitor</h1>
          <p>Sign in to access your dashboard</p>
        </div>
        
        {error && (
          <div className="error-message">
            {error}
          </div>
        )}
        
        <button 
          className="google-login-button" 
          onClick={handleGoogleLogin}
          disabled={loading}
        >
          <svg viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
            <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
              <path fill="#4285F4" d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"/>
              <path fill="#34A853" d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"/>
              <path fill="#FBBC05" d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"/>
              <path fill="#EA4335" d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"/>
            </g>
          </svg>
          <span>{loading ? 'Signing in...' : 'Sign in with Google'}</span>
        </button>
        
        <div className="login-footer">
          <p>Only authorized email addresses can access this application.</p>
        </div>
      </div>
    </div>
  );
};

export default Login; 