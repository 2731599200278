import React, { useState, useEffect, useCallback } from 'react';
import ChatList from './ChatList';
import ChatDetail from './ChatDetail';

const Dashboard = () => {
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [ticketIdSearch, setTicketIdSearch] = useState('');
  const [maxPointsFilter, setMaxPointsFilter] = useState('');
  const [agentNameFilter, setAgentNameFilter] = useState('');
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [agents, setAgents] = useState([]);

  // Predefined score options for the dropdown
  const scoreOptions = [
    { value: '', label: 'All Scores' },
    { value: '5', label: '5 or below' },
    { value: '6', label: '6 or below' },
    { value: '7', label: '7 or below' },
    { value: '8', label: '8 or below' },
    { value: '9', label: '9 or below' }
  ];

  const fetchChats = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      
      // Build query parameters for the API call
      const queryParams = new URLSearchParams();
      queryParams.append('page', page);
      
      // Add search parameters if they exist
      if (ticketIdSearch.trim()) {
        queryParams.append('ticket_id', ticketIdSearch.trim());
      }
      
      if (maxPointsFilter) {
        queryParams.append('max_points', maxPointsFilter);
      }
      
      if (agentNameFilter.trim()) {
        queryParams.append('agent_name', agentNameFilter.trim());
      }
      
      const response = await fetch(`/api/dashboard/chats?${queryParams.toString()}`);
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      setChats(data.chats || []);
      setTotalPages(data.pages || 1);
    } catch (error) {
      console.error('Error fetching chats:', error);
      setError('Failed to load chats. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, [page, ticketIdSearch, maxPointsFilter, agentNameFilter]);

  // Fetch list of agents for the filter dropdown
  const fetchAgents = useCallback(async () => {
    try {
      const response = await fetch('/api/dashboard/agents');
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      setAgents(data.agents || []);
    } catch (error) {
      console.error('Error fetching agents:', error);
      // Don't set an error state here, as it's not critical
    }
  }, []);

  useEffect(() => {
    fetchChats();
  }, [fetchChats]);

  useEffect(() => {
    fetchAgents();
  }, [fetchAgents]);

  // Debounce search input to prevent too many API calls
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setTicketIdSearch(value);
    
    // Clear any existing timeout
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    
    // Set a new timeout to delay the API call
    const timeout = setTimeout(() => {
      setPage(1); // Reset to first page when search changes
    }, 500); // 500ms delay
    
    setSearchTimeout(timeout);
  };

  const handleMaxPointsChange = (e) => {
    const value = e.target.value;
    setMaxPointsFilter(value);
    setPage(1); // Reset to first page when filter changes
  };

  const handleAgentNameChange = (e) => {
    const value = e.target.value;
    setAgentNameFilter(value);
    
    // Clear any existing timeout
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    
    // Set a new timeout to delay the API call
    const timeout = setTimeout(() => {
      setPage(1); // Reset to first page when filter changes
    }, 500); // 500ms delay
    
    setSearchTimeout(timeout);
  };

  const handleClearFilters = () => {
    setTicketIdSearch('');
    setMaxPointsFilter('');
    setAgentNameFilter('');
    setPage(1);
  };

  return (
    <div className="dashboard">
      {error && (
        <div className="error-message">
          {error}
          <button onClick={fetchChats}>Retry</button>
        </div>
      )}
      
      <div className="chat-list-container">
        <div className="filters-container">
          <div className="search-filter">
            <input
              type="text"
              placeholder="Search by Ticket ID"
              value={ticketIdSearch}
              onChange={handleSearchChange}
              className="ticket-search"
            />
          </div>
          <div className="points-filter">
            <select
              value={maxPointsFilter}
              onChange={handleMaxPointsChange}
              className="points-dropdown"
            >
              {scoreOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="agent-filter">
            <input
              type="text"
              list="agents-list"
              placeholder="Filter by Primary Agent"
              value={agentNameFilter}
              onChange={handleAgentNameChange}
              className="agent-search"
            />
            <datalist id="agents-list">
              {agents.map(agent => (
                <option key={agent.id} value={agent.name} />
              ))}
            </datalist>
          </div>
          {(ticketIdSearch || maxPointsFilter || agentNameFilter) && (
            <button 
              onClick={handleClearFilters}
              className="clear-filters"
            >
              Clear Filters
            </button>
          )}
        </div>
        
        <ChatList 
          chats={chats}
          onSelectChat={setSelectedChat}
          loading={loading}
          page={page}
          totalPages={totalPages}
          onPageChange={setPage}
        />
      </div>
      
      <div className="chat-detail-container">
        {selectedChat && <ChatDetail chat={selectedChat} />}
      </div>
    </div>
  );
};

export default Dashboard; 