import React from 'react';

const ChatDetail = ({ chat }) => {
  if (!chat) return null;

  return (
    <div className="chat-detail">
      <div className="chat-detail-header">
        <div className="header-main">
          <div className="header-left">
            <h2>Ticket #{chat.ticket_id}</h2>
            <div className="chat-meta">
              <div className="chat-labels">
                {chat.labels && chat.labels.map(label => (
                  <span key={label} className="label">{label}</span>
                ))}
              </div>
              <span className="date">Created: {new Date(chat.created_at).toLocaleString()}</span>
            </div>
          </div>
          <div className="agents-summary">
            <div className="agents-list">
              {chat.agents.map((agent) => (
                <div 
                  key={agent.agency_id} 
                  className={`agent-pill ${agent.is_primary ? 'primary' : ''}`}
                >
                  <span className="agent-initial">{agent.full_name.charAt(0)}</span>
                  <span className="agent-name">{agent.full_name}</span>
                  <span className="message-count">{agent.message_count}</span>
                </div>
              ))}
            </div>
            <div className="total-messages">
              {chat.agent_message_count} agent / {chat.customer_message_count} customer messages
            </div>
          </div>
        </div>
      </div>

      {/* AI Analysis Section */}
      {chat.analysis_results && (
        <div className="analysis-section">
          <h3>AI Analysis</h3>
          
          <div className="scores-grid">
            <div className="score-item">
              <div className="score-header">
                <label>Engagement & Rapport</label>
                <span className="raw-score">{chat.analysis_results.scores.engagement}/10</span>
              </div>
              <div className="score-bar-container">
                <div className="score-bar">
                  <div 
                    className="score-fill" 
                    style={{ width: `${chat.analysis_results.scores.engagement * 10}%` }} 
                  />
                </div>
                <span className="score-value">{chat.analysis_results.scores.engagement}</span>
              </div>
            </div>

            <div className="score-item">
              <div className="score-header">
                <label>Information Clarity</label>
                <span className="raw-score">{chat.analysis_results.scores.clarity}/10</span>
              </div>
              <div className="score-bar-container">
                <div className="score-bar">
                  <div 
                    className="score-fill" 
                    style={{ width: `${chat.analysis_results.scores.clarity * 10}%` }} 
                  />
                </div>
                <span className="score-value">{chat.analysis_results.scores.clarity}</span>
              </div>
            </div>

            <div className="score-item">
              <div className="score-header">
                <label>Sales & Persuasion</label>
                <span className="raw-score">{chat.analysis_results.scores.persuasion}/10</span>
              </div>
              <div className="score-bar-container">
                <div className="score-bar">
                  <div 
                    className="score-fill" 
                    style={{ width: `${chat.analysis_results.scores.persuasion * 10}%` }} 
                  />
                </div>
                <span className="score-value">{chat.analysis_results.scores.persuasion}</span>
              </div>
            </div>

            <div className="score-item">
              <div className="score-header">
                <label>Handling Objections</label>
                <span className="raw-score">{chat.analysis_results.scores.objections}/10</span>
              </div>
              <div className="score-bar-container">
                <div className="score-bar">
                  <div 
                    className="score-fill" 
                    style={{ width: `${chat.analysis_results.scores.objections * 10}%` }} 
                  />
                </div>
                <span className="score-value">{chat.analysis_results.scores.objections}</span>
              </div>
            </div>

            <div className="score-item">
              <div className="score-header">
                <label>Conversion Effectiveness</label>
                <span className="raw-score">{chat.analysis_results.scores.conversion}/10</span>
              </div>
              <div className="score-bar-container">
                <div className="score-bar">
                  <div 
                    className="score-fill" 
                    style={{ width: `${chat.analysis_results.scores.conversion * 10}%` }} 
                  />
                </div>
                <span className="score-value">{chat.analysis_results.scores.conversion}</span>
              </div>
            </div>

            <div className="score-item overall-score">
              <div className="score-header">
                <label>Overall Performance</label>
                <span className="raw-score">{chat.analysis_results.scores.overall}/10</span>
              </div>
              <div className="score-bar-container">
                <div className="score-bar">
                  <div 
                    className="score-fill" 
                    style={{ width: `${chat.analysis_results.scores.overall * 10}%` }} 
                  />
                </div>
                <span className="score-value">{chat.analysis_results.scores.overall}</span>
              </div>
            </div>
          </div>

          {/* Overall Assessment */}
          <div className="analysis-details">
            <div className="analysis-feedback">
              <h4>Overall Assessment</h4>
              <p>{chat.analysis_results.overall_feedback || 'No feedback available'}</p>
            </div>

            <div className="analysis-columns">
              {/* Strengths Section */}
              <div className="key-points">
                <h4>Strengths</h4>
                <ul>
                  {(chat.analysis_results.key_points || []).map((point, index) => (
                    <li key={index}>
                      <span className="bullet">✓</span>
                      {point}
                    </li>
                  ))}
                </ul>
              </div>

              {/* Areas for Improvement Section */}
              <div className="suggestions">
                <h4>Areas for Improvement</h4>
                <div className="suggestions-list">
                  {(chat.analysis_results.suggestions || []).map((suggestion, index) => (
                    <div key={index} className="suggestion-item">
                      <div className="suggestion-content">
                        <div className="suggestion-text">
                          <span className="icon">→</span>
                          {suggestion}
                        </div>
                        {chat.analysis_results.examples[index] && (
                          <div className="suggestion-example">
                            <span className="example-label">Example:</span>
                            <span className="example-text">"{chat.analysis_results.examples[index]}"</span>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Suggested Responses Section */}
      {chat.analysis_results?.suggested_responses?.length > 0 && (
        <div className="analysis-section">
          <h3>Suggested Responses</h3>
          <div className="suggested-responses">
            {chat.analysis_results.suggested_responses.map((response, index) => (
              <div key={index} className="suggested-response">
                <div className="response-type">
                  {response.query}
                </div>
                <div className="response-content">
                  {response.suggestion}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="chat-transcript-section">
        <h3>Conversation Transcript</h3>
        <div className="chat-transcript">
          {(chat.transcript || []).map((message, index) => (
            <div 
              key={index} 
              className={`message ${message.is_customer ? 'customer' : 'agent'}`}
            >
              <div className="message-header">
                <span className="sender">{message.sender}</span>
                <span className="time">
                  {new Date(message.created_at).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit'
                  })}
                </span>
              </div>
              <div className="message-content">{message.message}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChatDetail; 