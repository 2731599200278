import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';

const Admin = () => {
  const [emails, setEmails] = useState([]);
  const [newEmail, setNewEmail] = useState('');
  const [newName, setNewName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const { user } = useAuth();

  const fetchEmails = async () => {
    setLoading(true);
    setError(null);
    
    try {
      const response = await fetch('/api/admin/authorized-emails');
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      setEmails(data.emails || []);
    } catch (error) {
      console.error('Error fetching authorized emails:', error);
      setError('Failed to load authorized emails.');
    } finally {
      setLoading(false);
    }
  };

  const handleAddEmail = async (e) => {
    e.preventDefault();
    
    if (!newEmail.trim()) {
      setError('Email is required');
      return;
    }
    
    setLoading(true);
    setError(null);
    setSuccess(null);
    
    try {
      const response = await fetch('/api/admin/authorized-emails', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: newEmail.trim(),
          name: newName.trim()
        })
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }
      
      await response.json();
      setSuccess(`Email ${newEmail} authorized successfully`);
      setNewEmail('');
      setNewName('');
      fetchEmails();
    } catch (error) {
      console.error('Error adding authorized email:', error);
      setError(error.message || 'Failed to add authorized email');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteEmail = async (id) => {
    if (!window.confirm('Are you sure you want to revoke access for this email?')) {
      return;
    }
    
    setLoading(true);
    setError(null);
    setSuccess(null);
    
    try {
      const response = await fetch(`/api/admin/authorized-emails/${id}`, {
        method: 'DELETE'
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      setSuccess('Email authorization revoked successfully');
      fetchEmails();
    } catch (error) {
      console.error('Error deleting authorized email:', error);
      setError('Failed to revoke email authorization');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmails();
  }, []);

  if (!user) {
    return (
      <div className="admin-page">
        <h1>Admin Dashboard</h1>
        <p>You must be logged in to access this page.</p>
      </div>
    );
  }

  return (
    <div className="admin-page">
      <h1>Admin Dashboard</h1>
      <p>Manage authorized emails for system access</p>
      
      {error && <div className="error-message">{error}</div>}
      {success && <div className="success-message">{success}</div>}
      
      <div className="add-email-section">
        <h2>Add Authorized Email</h2>
        <form onSubmit={handleAddEmail}>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              placeholder="Enter email to authorize"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="name">Name (optional):</label>
            <input
              type="text"
              id="name"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              placeholder="Enter name"
            />
          </div>
          <button type="submit" disabled={loading}>
            {loading ? 'Adding...' : 'Add Email'}
          </button>
        </form>
      </div>
      
      <div className="emails-list-section">
        <h2>Authorized Emails</h2>
        {loading ? (
          <div className="loading">Loading...</div>
        ) : emails.length === 0 ? (
          <div className="no-emails">No authorized emails found</div>
        ) : (
          <table className="emails-table">
            <thead>
              <tr>
                <th>Email</th>
                <th>Name</th>
                <th>Role</th>
                <th>Added On</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {emails.map(email => (
                <tr key={email.id}>
                  <td>{email.email}</td>
                  <td>{email.name || '-'}</td>
                  <td>{email.role}</td>
                  <td>{new Date(email.created_at).toLocaleDateString()}</td>
                  <td>
                    <button 
                      onClick={() => handleDeleteEmail(email.id)}
                      className="delete-button"
                      disabled={loading}
                    >
                      Revoke
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default Admin; 